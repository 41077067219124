import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import TestimFamily from '../../images/testimfamily.png';

const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 8000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const dotsSettings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    speed: 1000,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    focusOnSelect: true,
};
class WhatParentsSay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    render() {
        return (
            <section className="page-section bg-secondary text-white testimsec">
                <h3 className="yellowtxt">What Parents Say</h3>
                <div
                    id="myCarousel"
                    className="carousel slide"
                    data-ride="carousel"
                >
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        {...dotsSettings}
                        className="carousel-indicators"
                    >
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </Slider>
                    <Slider
                        {...settings}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        className="carousel-inner"
                    >
                   {/*     <div className="item carousel-item active">
                            <p className="testimonial">
                                “Staff have excellent knowledge to support kids 
                                with ASD and additional needs. They are loving 
                                and supportive. Highly recommend.”
                            </p>  */}
                            {/* <p className="overview">Jenny R. - Winnetka</p> */}
              {/*           </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “Absolutely fantastic place, they go above and beyond. 
                                A place you never have to say sorry. 
                                My daughter adores rock the spectrum. Thankgod we 
                                have one in Geelong.”
                            </p>
                            <p className="overview">Emma B</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            “What a fantastic facility. The owners are just wonderful and welcoming! 
                            My 8yr old ASD son had the best time.... so many choices. 
                            While my 3 yr old loved playing with the shopping cart 
                            playing supermarkets and dressing up as a fireman. 
                            I highly recommend this wonderful place for the whole family. 
                            Thanks guys!”
                            </p>  */}
                            {/* <p className="overview">
                                Ken & Monique F. - Tarzana
                            </p> */}
              {/*          </div>

                        <div className="item carousel-item">
                            <p className="testimonial">
                                “Wonderful! So many options for different skill levels, 
                                needs and preferences. We will definitely be back."
                            </p>
                            <p className="overview">
                            Bec K
                            </p>
                        </div>

                        <div className="item carousel-item">
                            <p className="testimonial">
                                “Amazing space with oodles of engaging equipment and activities!! 
                                So considered and lovely staff. My boys loved it!"
                            </p>
                            <p className="overview">
                            Vicki V
                            </p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “We absolutely love this place! The facilities are amazing and 
                                the team that work here are so welcoming and great with the children. 
                                It has been an absolute life saver for us throughout 
                                these tricky times being able to use the facilities for private hire!"
                            </p>
                            <p className="overview">
                            Bree T
                            </p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “Highly recommended for all you child's sensory needs. 
                                Great friendly welcoming space"
                            </p>
                            <p className="overview">
                            Charlene L
                            </p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            What a terrific space for our little people. 
                            We are looking forward to visiting again. Great value for $$"
                            </p>
                            <p className="overview">
                            Jessica A
                            </p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            Great place, plenty of things to keep the kids busy"
                            </p>
                            <p className="overview">
                            Jackie M
                            </p>
                        </div>  */}
                        <div className="item carousel-item active">
                            <p className="testimonial">
                                “A perfect place for play and parties! Our son had his 3rd birthday party here today with attendees aged 2-8 and everyone had a blast! Kon and Anne are amazing and so friendly, lots of fun equipment for big and small and the best part - we had the whole venue to ourselves! Would highly recommend!”
                            </p>  
                             <p className="overview">- Tara K.</p> 
                         </div>
                         <div className="item carousel-item">
                            <p className="testimonial">
                                “We visited here through a recommendation and we will definitely be back! The space was excellently maintained and accommodated for hours of fun. Being able to bring personal food in was a huge plus. My children-and myself- had such a great time. The owners were very welcoming and kind-thank you for providing such an amazing place!”
                            </p>
                            <p className="overview">- Molly A.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “The place was wonderful. First time visiting. We can see the love the owners have poured into it. Very clean and well maintained. The staff were very attentive and diligent. We spent 3 hours there and the kids still didn't want to leave. There are only a small selection of snacks but we are allowed to bring our own food which was a nice change.”
                            </p>
                            <p className="overview">- Crystal P.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “My daughter and I went yesterday for the first time and she loved it!! She loves to climb, jump, swing and spin and this place has everything! There’s not many places we can go where she can be entertained, feel comfortable and confident to be herself and have fun and not want to leave so we’re so excited to have a new go to place! The staff were so friendly and approachable and the area was clean and there are so many options of play to choose from. 10/10 recommend :)”
                            </p>
                            <p className="overview">- Rheannon J.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “I've been bringing my client to We Rock the Spectrum in Berwick for the past 4 months, and it has been an amazing experience. The trampoline is a big hit with my client, providing a fun and engaging way for him to get some exercise and sensory input. It's fantastic to see more facilities like We Rock the Spectrum catering to the unique needs of people with autism. I highly recommend this place for anyone looking for a supportive and fun space for individuals with autism!”
                            </p>
                            <p className="overview">- Tegh T.</p>
                        </div>
                    </Slider>
                    <img
                        className="quoteimg"
                        src={TestimFamily}
                        alt="Testimonials family"
                    />
                </div>
            </section>
        );
    }
}

export default WhatParentsSay;
